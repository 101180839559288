import { useEffect, useState } from "react";

import { SearchResult } from "contracts/search";

import SkyData from "./data/skybox-data.json";
import Search from "./components/search/search-box";
import Button from "./components/button/button.styles";
import * as CSS from "./app.styles";

const ASSET_STORE_URL = "https://assetstore.unity.com/packages/slug/275151";

const App = () => {
	const [pic, setPic] = useState("01");
	const [isSearching, setIsSearching] = useState(false);
	const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
	const [showIsCopying, setIsShowingCopying] = useState(false);
	const [skyName, setSkyName] = useState("midday_013");

	const skyInformation = SkyData.find(
		(sky) => sky.name === `500_skyboxes__${skyName}`
	);

	useEffect(() => {
		if (showIsCopying) {
			let timer1 = setTimeout(() => setIsShowingCopying(false), 2000);
			return () => {
				clearTimeout(timer1);
			};
		}
	}, [showIsCopying]);

	const onSearch = (timeOfDay: number, cloudCoverage: number) => {
		const convertedTime = Math.floor(timeOfDay / 100);

		const foundSkies = SkyData.filter((sky) => {
			return sky.clouds == cloudCoverage && sky.time == convertedTime;
		});

		setSearchResults(foundSkies);

		if (foundSkies.length) {
			setSkyName(foundSkies[0].name.toString().split("__")[1]);
		}
	};

	const handleResultClicked = (pic: SearchResult) => {
		setSkyName(pic.name.toString().split("__")[1]);
		setIsSearching(false);
	};

	return (
		<CSS.App>
			<CSS.SkyBackground $skyName={skyName} $pic="01" $hide={false} />
			<CSS.SkyBackground $skyName={skyName} $pic="02" $hide={pic === "01"} />
			<CSS.TitleImgContainer $hide={!isSearching}>
				<CSS.Title>Ether Skybox collection</CSS.Title>
				<a href={ASSET_STORE_URL} target="_blank" rel="noreferrer">
					<CSS.AssetStoreImg
						src="assets/unity/asset-store.png"
						alt="Buy on asset store"
					/>
				</a>
			</CSS.TitleImgContainer>
			<CSS.ContentContainer $hide={isSearching}>
				<CSS.SkyName>{`ether_skyboxes__${skyName}`}</CSS.SkyName>
				<CSS.ButtonContainer>
					<Button onClick={() => setPic(pic === "01" ? "02" : "01")}>
						Look {pic === "01" ? "North" : "South"}
					</Button>
					<Button
						onClick={() => {
							navigator.clipboard.writeText(`ether__${skyName}`);
							setIsShowingCopying(true);
						}}
					>
						{showIsCopying ? "Copied to clipboard!" : "Copy skybox name"}
					</Button>
					<Button onClick={() => setIsSearching(!isSearching)}>
						Open search
					</Button>
				</CSS.ButtonContainer>
			</CSS.ContentContainer>
			<Search
				isHidden={!isSearching}
				onClose={() => setIsSearching(false)}
				onSearchSubmit={onSearch}
				results={searchResults}
				handleResultClicked={handleResultClicked}
				onClearSearch={() => setSearchResults([])}
			/>
		</CSS.App>
	);
};

export default App;
