import styled from "styled-components";

const button = styled.button`
  border: solid 2px white;
  border-radius: 10px;
  color: white;
  background: none;
  padding: 0.7rem;
  cursor: pointer;

  transition: all 300ms;

  &:hover {
    background: white;
    color: black;
  }
`

export default button
