import styled from "styled-components";

export const App = styled.div`
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	position: fixed;
	overflow: hidden;
`;

type SkyBackgroundStyleProps = {
	$skyName: string;
	$pic: string;
	$hide: boolean;
};

export const SkyBackground = styled.div<SkyBackgroundStyleProps>`
	background-image: ${({ $skyName, $pic }) =>
		`url("/assets/img/500_skyboxes__${$skyName}/500_skyboxes__${$skyName}__${$pic}.png")`};
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #222222;
	background-position: center;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	position: fixed;
	overflow: hidden;
	opacity: ${({ $hide }) => ($hide ? 0 : 1)};
	transition: opacity 200ms ease-out;
`;

type ContentContainerStyleProps = {
	$hide: boolean;
};

export const ContentContainer = styled.div<ContentContainerStyleProps>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	background: rgba(0, 0, 0, 0.5);
	padding: 1rem;
	transform: translateY(${({ $hide }) => ($hide ? "-52%" : "0")});
	transition: transform 300ms ease-out;

	> div {
		opacity: ${({ $hide }) => ($hide ? 0 : 1)};
	}

	@media (min-width: 640px) {
		gap: 2rem;
		padding: 3rem;
		transform: translateY(${({ $hide }) => ($hide ? "-100%" : "0")});
	}
`;

export const TitleImgContainer = styled.div<ContentContainerStyleProps>`
	z-index: 2000;
	position: absolute;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;

	display: ${({ $hide }) => ($hide ? "none" : "flex")};

	transition: opacity 300ms ease-out;

	@media (min-width: 640px) {
		position: absolute;
		left: 1rem;
		top: 1rem;
		max-width: 300px;
		display: block;
	}
`;

export const Title = styled.div`
	font-family: "Noto Sans KR", sans-serif;
	font-weight: 500;
	color: white;
	font-size: 1.3rem;
	line-height: 1.2rem;
	cursor: default;
	align-self: flex-start;
	text-align: center;
	width: 100%;

	@media (min-width: 640px) {
		text-align: left;
		width: auto;
	}
`;

export const AssetStoreImg = styled.img`
	width: 140px;
	margin-top: 1rem;

	@media (min-width: 640px) {
		width: 200px;
	}
`;

export const SkyName = styled.div`
	font-family: "Noto Sans KR", sans-serif;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 2rem;
	color: white;
	cursor: default;

	margin-top: 0rem;

	@media (min-width: 640px) {
		margin-top: 0;
		font-size: 2rem;
	}
`;

export const SkyDescription = styled.div`
	font-family: "Noto Sans KR", sans-serif;
	font-weight: 300;
	font-size: 1.4rem;
	line-height: 2rem;
	text-align: center;
	color: white;
	cursor: default;
`;

export const FlipButton = styled.button`
	border: solid 2px white;
	border-radius: 10px;
	color: white;
	background: none;
	padding: 0.7rem;
	cursor: pointer;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
	width: 100%;

	@media (min-width: 640px) {
		flex-direction: row;
		justify-content: center;
		gap: 1rem;
	}
`;

export const MaterialSphereContainer = styled.div<ContentContainerStyleProps>`
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-top: 2rem;
	opacity: ${({ $hide }) => ($hide ? 0 : 1)};
	transition: opacity 200ms ease-out;
`;

export const SearchResultsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 1rem;
	margin: 2rem 0;

	overflow-x: scroll;
`;

export const SearchResult = styled.div`
	cursor: pointer;
	z-index: 1000;

	filter: brightness(80%);

	:hover {
		filter: brightness(150%);
	}

	img {
		transition: all 300ms ease;

		border-radius: 10px;
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
		border: 1px solid rgba(255, 255, 255, 0.3);
	}
`;
