import { TimeOfDay } from 'contracts/time'

export const getTimeOfDayById = (timeId: number): TimeOfDay => {
  switch (timeId) {
    case 1: return 'dawn'
    case 2: return 'sunrise'
    case 3: return 'morning'
    case 4: return 'day'
    case 5: return 'afternoon'
    case 6: return 'sunset'
    case 7: return 'dusk'
    default: return 'night'
  }
}