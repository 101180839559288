import styled from 'styled-components'

export const Slider = styled.div`
  width: 100%;
  margin: 1rem 0 3rem 0;
  position: relative;
`

export const Label = styled.div`
  font-size: 1.4rem;
  color: white;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
`

export const InputSlider = styled.input`
  margin-top: 29px;
  width: 100%;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.02);
    height: 30px;
    width: 30px;
    border-radius: 22px;
    background: rgba(255,255,255,1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15px;
  }

  &::-moz-focus-outer {
    border: 0;
  }
`

type ValueLabelStyleProps = {
  percentage: number
}

export const ValueLabel = styled.span<ValueLabelStyleProps>`
    position: absolute;
    display: block;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
    margin-top: 20px;

    left: ${({ percentage }) => `calc((100% * ${percentage}) - (30px * ${percentage}))`};
    transform: translateX(-10%);
    
    font-size: 1rem;
    color: white;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 300;
`
