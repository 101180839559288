import styled from "styled-components";

type SearchBoxStyleProps = {
	$hide: boolean;
};

export const SearchBox = styled.div<SearchBoxStyleProps>`
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	padding: 2rem;
	position: absolute;
	bottom: 0;

	padding-bottom: 8rem;

	transform: translateY(${({ $hide }) => ($hide ? "100%" : "0")});
	transition: transform 300ms ease-out;

	@media (min-width: 640px) {
		padding: 3rem;
		padding-bottom: 2rem;
	}
`;

export const SearchTitle = styled.div`
	font-size: 2.3rem;
	color: white;
	font-family: "Noto Sans KR", sans-serif;
	font-weight: 500;
	margin-bottom: 2rem;
`;
