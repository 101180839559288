import { FC, useState } from "react";

import { SearchResult } from "contracts/search";

import { getTimeOfDayById } from "../../utils/time.utils";
import Slider from "../slider/slider";
import Button from "../button/button.styles";
import * as AppStyles from "../../app.styles";

import * as CSS from "./search-box.styles";

type SearchBoxProps = {
	onClose: () => void;
	isHidden: boolean;
	onSearchSubmit: (timeOfDay: number, cloudCoverage: number) => void;
	onClearSearch: () => void;
	results: SearchResult[];
	handleResultClicked: (searchResult: SearchResult) => void;
};

const SearchBox: FC<SearchBoxProps> = ({
	onClose,
	isHidden,
	onSearchSubmit,
	results,
	handleResultClicked,
	onClearSearch,
}) => {
	const [timeOfDay, setTimeOfDay] = useState(0);
	const [cloudCoverage, setCloudCoverage] = useState(0);

	const getDayLabel = () => {
		const timeOfDayLabel = getTimeOfDayById(Math.floor(timeOfDay / 100));
		return `${timeOfDayLabel.charAt(0).toUpperCase()}${timeOfDayLabel.substring(
			1,
			timeOfDayLabel.length
		)}`;
	};

	const showSearchResults = results.length > 0;

	return (
		<CSS.SearchBox $hide={isHidden}>
			<CSS.SearchTitle>
				{showSearchResults ? "Search results" : "Search for sky"}
			</CSS.SearchTitle>
			{showSearchResults && (
				<AppStyles.SearchResultsContainer>
					{results.map((searchResult) => {
						const searchName = searchResult.name.toString().split("__")[1];
						return (
							<AppStyles.SearchResult
								onClick={() => handleResultClicked(searchResult)}
								key={`search_img_${searchResult.name}`}
							>
								<img
									height={100}
									width={100}
									style={{ objectFit: "cover" }}
									alt={searchName}
									src={`assets/img/500_skyboxes__${searchName}/500_skyboxes__${searchName}__01.png`}
								/>
							</AppStyles.SearchResult>
						);
					})}
				</AppStyles.SearchResultsContainer>
			)}
			{!showSearchResults && (
				<>
					<Slider
						label="Time of day"
						range={[0, 700]}
						value={timeOfDay}
						onChange={setTimeOfDay}
						valueLabel={getDayLabel()}
					/>
					<Slider
						label="Cloud coverage"
						range={[0, 10]}
						value={cloudCoverage}
						onChange={setCloudCoverage}
					/>
				</>
			)}
			<AppStyles.ButtonContainer>
				<Button onClick={onClose}>Hide search</Button>
				{showSearchResults && (
					<Button onClick={() => onClearSearch()}>Clear results</Button>
				)}
				{!showSearchResults && (
					<Button onClick={() => onSearchSubmit(timeOfDay, cloudCoverage)}>
						Apply
					</Button>
				)}
			</AppStyles.ButtonContainer>
		</CSS.SearchBox>
	);
};

export default SearchBox;
