import { FC, useState } from "react"

import * as CSS from './slider.styles'

type SliderProps = {
  label: string
  valueLabel?: string
  range: number[]
  value: number
  onChange: (value: number) => void
}

const Slider: FC<SliderProps> = ({ label, range, value, onChange, valueLabel }) => {

  const [thumbPosition, setThumbPosition] = useState(0)

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const newValue: number = e.target.value as unknown as number
    onChange(newValue)

    if (valueLabel) {
      const percentage = newValue / range[1]
      setThumbPosition(percentage)
    }
  }

  return (
    <CSS.Slider>
      <CSS.Label>{label}</CSS.Label>
      <CSS.InputSlider
        type='range'
        min={range[0]}
        max={range[1]}
        value={value}
        onChange={handleSliderChange}
      />
      {valueLabel && <CSS.ValueLabel percentage={thumbPosition}>{valueLabel}</CSS.ValueLabel>}
    </CSS.Slider>
  )
}

export default Slider
